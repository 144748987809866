<template>
	<Page class="verify-email">

		<Loader v-if="auth.verifyEmail.isLoading" />
		<Error v-if="auth.verifyEmail.error" message="Token has been expired">
			<v-btn
					:to="paths().register"
					large
					outlined
					class="btn-submit"
			>Go to Register Page
			</v-btn>
		</Error>
		<div class="email-verify-success" v-if="auth.verifyEmail.success">
			<Title title="Congratulations!" />
			<p class="paragraph">You have been successfully registered.</p>
			<v-btn
					:to="paths().login"
					large
					outlined
					class="btn-submit"
			>Please login
			</v-btn>
		</div>

	</Page>
</template>

<script>
	import Page from "../components/layouts/LayoutPage";
	import {
		mapActions,
		mapState
	} from "vuex";
	import Loader from "../components/common/Loader";
	import Error from "../components/common/Error";
	import { mixinPaths } from "../mixins/mixinPaths";
	import Title from "../components/common/Title";


	export default {
		name: "VerifyEmail",
		components: { Title, Error, Loader, Page },
		methods: {
			...mapActions( [
				'requestVerifyEmail',
				'requestVerifyEmailReset',
			] ),
		},
		mixins: [
			mixinPaths,
		],
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		created() {
			const { params, query } = this.$route;
			this.$store.dispatch(
				"requestVerifyEmail",
				`${ params.id }?expires=${ query.expires }&hash=${ query.hash }&signature=${ query.signature }`
			);
		},
		destroyed() {
			this.$store.dispatch( "requestVerifyEmailReset" );
		}
	};
</script>

<style scoped lang="scss">
	.verify-email {
		.email-verify-success {
			text-align: center;

			.paragraph {
				display: block;
				font-size: 1.6rem;
				margin-bottom: 6rem !important;
			}
		}
	}
</style>

<style lang="scss">
	.verify-email {
		.app-title {
			color: $c-success;
			margin-bottom: 4rem;
		}
	}
</style>
